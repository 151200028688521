@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/CormorantGaramond-SemiBold/CormorantGaramond-SemiBold.eot');
    src: url('./fonts/CormorantGaramond-SemiBold/CormorantGaramond-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CormorantGaramond-SemiBold/CormorantGaramond-SemiBold.woff2') format('woff2'),
        url('./fonts/CormorantGaramond-SemiBold/CormorantGaramond-SemiBold.woff') format('woff'),
        url('./fonts/CormorantGaramond-SemiBold/CormorantGaramond-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Cormorant Garamond';
    src: url('./fonts/CormorantGaramond-Bold/CormorantGaramond-Bold.eot');
    src: url('./fonts/CormorantGaramond-Bold/CormorantGaramond-Bold.eot?#iefix') format('embedded-opentype'),
        url('./fonts/CormorantGaramond-Bold/CormorantGaramond-Bold.woff2') format('woff2'),
        url('./fonts/CormorantGaramond-Bold/CormorantGaramond-Bold.woff') format('woff'),
        url('./fonts/CormorantGaramond-Bold/CormorantGaramond-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'Century Gothic';
    src: url('./fonts/CenturyGothic.ttf');
    src: url('./fonts/CenturyGothic.ttf') format('truetype');
    font-style: normal;
    font-display: swap;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body{
    margin:0;
}
html,body{
    width:100%;
    height:100%;
    overflow:hidden;
    position: fixed;
    font-family: 'Cormorant Garamond';
    font-weight: normal;
}


body{
    margin:0;
}


.main-canvas{
    width:100%;
    height:100vh;
    position: absolute;
    top: 0;
    z-index: 2;
}

.skyz{
    position: absolute;
    width:100%;
    height:100vh;
    top:0;
    z-index: 1;
}


.sky{
    position: absolute;
    width:100%;
    height:100vh;
    top:0;
    z-index: 1;
    opacity: 0;
}

.white-sky{
    background: linear-gradient(180deg,  #fffdf600 0%, #fffdf645 18%,#FFFDF6 42%);
    z-index: 100;
}

.gradient1{
    background: linear-gradient(180deg, rgba(2,23,45,1) 0%, rgba(0,57,107,1) 33%, rgba(66,146,208,1) 66%, rgba(144,187,219,1) 100%);   
    z-index: 1;
    opacity: 1;
}

.gradient2{
    background: linear-gradient(180deg, #02172D 0%, #3089CF 25%, #A8CCE5 45%);
    z-index: 2;
    opacity: 0;
}

.gradient3{
    background: linear-gradient(180deg, #6DA2E9 0%, #B3B6E2 25%, #BFC1E3 45%);
    z-index: 3;
}

.gradient4{
    background: linear-gradient(180deg, #85B1EA 0%, #D1E6F1 25%, #E4EFF2 45%);
    z-index: 4;
}
.gradient5{
    background: linear-gradient(180deg, #83CDF1 0%, #D1E6F1 25%, #E5EFF2 45%);
    z-index: 5;
}
.gradient6{
    background: linear-gradient(180deg, #A7D4F3 0%, #CDE0F2 25%, #F9EEF0 45%);
    z-index: 6;
}
.gradient7{
    background: linear-gradient(180deg, #BFB6DB 0%, #D4C8E0 25%, #F4E5E7 45%);
    z-index: 7;
}
.gradient8{
    background: linear-gradient(180deg, #7C7BC7 0%, #A69BCE 25%, #FADBDC 45%);
    z-index: 8;
}
.gradient9{
    background: linear-gradient(180deg, #676EB9 0%, #9D99CD 25%, #E9D8EA 45%);
    z-index: 9;
}
.gradient10{
    background: linear-gradient(180deg, #09295A 0%, #415783 25%, #ADB2D4 45%);
    z-index: 10;
}
.gradient11{
    background: linear-gradient(180deg, #092E64 0%, #375789 25%, #738DB8 45%);
    z-index: 11;
}


.gradientLast{
    background: linear-gradient(180deg, #09204A 0%, #0A3876 25%, #315799 45%);
    z-index: 20;
}

    
.main-logo {
    position: absolute;
    top: 55px; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 12;
}

@media screen and (max-width: 768px) {
    .main-logo {
        width:78px;
        top:41px;
    }
}

#fireworks,
#flowerdior,
#sketches {
position: absolute;
z-index: 1000;
pointer-events: none;
opacity: 0;
width: 100%;
}

#fireworks{
        transform: scale(.5) translateY(-60%);
}