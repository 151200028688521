.outro {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding-top: 55px;
    box-sizing: border-box;
    overflow: hidden; 
    transform:translateY(-100%);
}

.outro-title {
    font-family: 'Cormorant Garamond';
    font-weight: bold;
    font-size: 30px;
    line-height: 40px;
    margin: 0;
}

.outro-link {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    display: flex;
    width: max-content;
}
.outro-img.mobile {
    display: none;
}

.outro-img{
    max-width: 100%;
    max-height: 40vh;
}

@media screen and (max-width: 768px) {
    .outro-title {
        font-size: 20px;
        line-height: 24px;
    }
    .outro-img.mobile {
        display: block;
    }
    .outro-img.desktop {
        display: none;
    }
}
