.texts {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
    padding-top: 55px;
    box-sizing: border-box;
}
.text-step {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    padding: 0 5% 40px;
    box-sizing: border-box;
    opacity: 0;
}
.text-step.active {
    opacity: 1;
}
.quote-text {
    position: relative;
    font-family: 'Cormorant Garamond';
    font-weight: bold;
    font-size: 26px;
    line-height: 31px;
    color: #1E335D;
    margin-bottom: 20px;
    max-width: 340px;
}
.quote-text::before {
    content:'';
    position: absolute;
    top: 5px;
    left: -30px;
    width: 22px;
    height: 18px;
    background: url('../img/quote-blue-left.svg') center no-repeat;
    background-size: contain;
    filter: grayscale(1);
    opacity: 0.3;
}
.quote-text::after {
    content: '';
    position: absolute;
    bottom: 5px;
    right: -30px;
    width: 22px;
    height: 18px;
    background: url('../img/quote-blue-right.svg') center no-repeat;
    background-size: contain;
    filter: grayscale(1);
    opacity: 0.3;
}
.quote-author {
    font-family: 'Cormorant Garamond';
    font-weight: normal;
    font-style: italic;
    font-size: 23px;
    line-height: 28px;
    color: #B19341;
}

.big-text {
    font-family: 'Cormorant Garamond';
    font-weight: bold;
    font-size: 40px;
    line-height: 40px;
    color: #1E335D;
}

.credit-text {
    font-family: 'Century Gothic';
    font-weight: normal;
    font-size: 13px;
    line-height: 22px;
    color: #1E335D;
    max-width: 390px;
}

#text1{
    transform:translateY(100%)
}

@media screen and (max-width: 768px) {
    .star-img {
        width: 90%;
    }
    .quote-text {
        font-size: 20px;
        line-height: 24px;
        max-width: 265px;
    }
    .quote-author {
        font-size: 18px;
        line-height: 22px;
    }
    .quote-text::before {
        width: 16px;
        height: 13px;
    }
    .quote-text::after {
        width: 16px;
        height: 13px;
    }
    .big-text {
        font-size: 26px;
        line-height: 28px;
    }
    .credit-text {
        font-size: 11px;
        line-height: 17px;
        max-width: 290px;
    }
}
