.quote-star {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
    padding-top: 55px;
    box-sizing: border-box;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    /*transition: all 2s .75s ease-out;*/
    transform: translateY(100px);
}

.quote-star.active {
    transform: translateY(0px);
    opacity: 1;
}

.quote-star.anim {
    transition: none;
}

.quote-star-author,
.quote-star-text,
.star-img-container {
    opacity: 0;
    transform: translateY(50%);
    transition: all 3s ease-in-out;
}

.active .quote-star-author,
.active .quote-star-text,
.active .star-img-container {
    opacity: 1;
    transform: translateY(0%)
}

.active .quote-star-text {
    transition:  all 1s 2s ease-in-out;
}

.active .quote-star-author {
    transition:  all 1s 3s ease-in-out;
}



.star-img {
    width: 100%;
    transform: translateX(-15%);
    animation: star 2s alternate infinite ease-in-out;
}

@keyframes star {
    0% {
        transform: translate(-15%, 0);
    }

    100% {
        transform: translate(-15%, 10px);
    }
}


.quote-star-text {
    position: relative;
    font-family: 'Cormorant Garamond';
    font-weight: bold;
    font-size: 36px;
    line-height: 41px;
    color: white;
    margin: 30px 0 20px;
}

.quote-star-text::before {
    content: '';
    position: absolute;
    top: 5px;
    left: -35px;
    width: 22px;
    height: 18px;
    background: url('../img/quote-blue-left.svg') center no-repeat;
    background-size: contain;
}

.quote-star-text::after {
    content: '';
    position: absolute;
    top: 5px;
    right: -35px;
    width: 22px;
    height: 18px;
    background: url('../img/quote-blue-right.svg') center no-repeat;
    background-size: contain;
}

.quote-star-author {
    font-family: 'Cormorant Garamond';
    font-weight: normal;
    font-style: italic;
    font-size: 25px;
    line-height: 30px;
    color: #1E335D;
}

@media screen and (max-width: 768px) {
    .star-img {
        width: 90%;
    }

    .quote-star-text {
        font-size: 26px;
        line-height: 29px;
    }

    .quote-star-author {
        font-size: 18px;
        line-height: 22px;
    }

    .quote-star-text::before {
        top: 2px;
        left: -24px;
        width: 16px;
        height: 13px;
    }

    .quote-star-text::after {
        top: 2px;
        right: -24px;
        width: 16px;
        height: 13px;
    }

}