.intro {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
    overflow: scroll;
    overflow: hidden;
    transition: all 1.5s ease-in-out;
}

.intro.off {
    opacity: 0;
    transform: translateY(-50px);
    pointer-events: none;
}

.snowflakes {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vw;
    z-index: 4;
    overflow: hidden;
    pointer-events: none;
    opacity: 0;
}

.snowflakes svg {
    width: 100% !important;
    height: auto !important;
}



.intro-step {
    position: absolute;
    opacity: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    padding: 0 5%;
    padding-top: 41px;
}

.intro-wrapper {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}



#step-1 .intro-wrapper {
    max-width: 546px;
}

#step-2 .intro-wrapper {
    max-width: 675px;
}

#step-3 .intro-wrapper {
    max-width: 675px;
}

#step-4 .intro-wrapper {
    max-width: 440px;
}

@media screen and (max-width: 768px) {
    .intro-wrapper {
        width: 90%;
        padding: 0 5%;
        box-sizing: border-box;
        margin: 0 auto;
    }
}

.intro-title {
    font-family: 'Cormorant Garamond';
    font-weight: bold;
    font-size: 44px;
    line-height: 44px;
}

.intro-paragraph {
    font-family: 'Cormorant Garamond';
    font-weight: normal;
    font-size: 21px;
    line-height: 31px;
}

.default-cta {
    display: inline-block;
    padding: 15px 25px;
    border-radius: 50px;
    background-color: #FFF;
    color: #003B6E;
    border: 1px solid transparent;
    transition: all 400ms ease-in-out;
    cursor: pointer;
    margin-top: 30px;
}

.default-cta:hover {
    background-color: transparent;
    color: #FFF;
    border: 1px solid #FFF;
}

.intro-step .cta-default {
    margin-top: 50px;
}

.explore {
    position: fixed;
    bottom: 0;
    height: 114px;
    left: 50%;
    transform: translateX(-50%);
    font-family: 'Century Gothic';
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    transition: all .5s ease-in-out;
}

.explore.off {
    opacity: 0;
    transfrom:translateY(50%);
}


.explore-line {
    width: 1px;
    height: 70px;
    background-color: #D8D8D8;
    margin-top: 20px;
}

.explore-text {
    animation: ex-text 2s alternate infinite ease-in-out;
}


.cta-start.off{
    opacity: 0;
    pointer-events:none;
}



@keyframes ex-text{
    0%{
        transform: translateY(0);
    }
    100%{
        transform: translateY(10px);
    }
}

@media screen and (max-width: 768px) {
    .intro-title {
        font-size: 26px;
        line-height: 28px;
    }

    .intro-paragraph {
        font-size: 18px;
        line-height: 22px;
    }
}

.intro-bottom{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.preload-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top:20px;
        font-family: 'Century Gothic';
        font-size: 13px;
        line-height:20px;
        transition: all .5s ease;
        position: absolute;
        top:30px;
        pointer-events: none;
}
.preload-wrapper.off{
    opacity: 0;
}

.preloader{
    height:3px;
    width:70px;
    background: rgba(255,255,255,.25);
    position: relative;
}
.preloader-bar{
    height:100%;
    width:0%;
    position: absolute;
    background: white;  
    transition: all .5s ease;
}

.preloader-text{
    margin-top: 8px;
}